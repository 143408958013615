import axios from 'axios';
import {baseURL} from '../api/url'

// const baseURL = 'https://devapi.wirewink.com/v1/';
const BEARER_TOKEN = '9ee93f4b-f0dd-4ee7-a31c-2f4086214b39';
const config = {
  headers: {
    'Authorization': `Bearer ${BEARER_TOKEN}`
  }
};
export const getPostsByCategory = async (category, page = 1) => {
  try {
    const response = await axios.get(`${baseURL}v1/Article/getByCategory?category=${category}&page=${page}&all=true`, config);
    return response.data
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};
export const getSinglePost = async (uri) => {
  try {
    const response = await axios.get(`${baseURL}v1/Article/get?uri=${uri}`, config);
    return response
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};

export const activatePost = async (uri, active) => {
  try {
    // const response = await axios.get(`${baseURL}v1/Article/delete?uri=${uri}`, config);
    const response = await axios.put(`${baseURL}v1/Article/activate/?uri=${uri}&isActive=${active}`, config);
    return response;
  } catch (error) {
    console.error('Error deleting post:', error);
    throw error;
  }
};
export const updatePost = async (post) => {
  try {
    const response = await axios.put(`${baseURL}v1/Article/update`, post, config);
    return response;
  } catch (error) {
    console.error('Error updating post:', error);
    throw error;
  }
};
export const getPostsByTitle = async (category, searchPhrase) => {
  try {
    // const response = await axios.put(`${baseURL}v1/Article/update`, post, config);
    const response = await axios.get(`${baseURL}v1/Article/search?category=${category}&title=${searchPhrase}`, config);
    return response.data;
  } catch (error) {
    console.error('Error updating post:', error);
    throw error;
  }
};
