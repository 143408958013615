import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  const login = () => {
    localStorage.setItem('isAuth', true);
    navigate('/'); // Navigate to '/' route
  };

  const logout = () => {
    localStorage.removeItem('isAuth');
  };

  const isAuthenticated = localStorage.getItem('isAuth') === 'true';

  return (
    <AuthContext.Provider value={{ login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
