import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../../App.css";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    // Check if the user is authenticated
    if (auth.isAuthenticated) {
      navigate("/"); // Redirect to '/' if authenticated
    }
  }, [auth.isAuthenticated, navigate]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setUsernameError(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (username.trim() === "") {
      setUsernameError(true);
      return;
    }

    if (password.trim() === "") {
      setPasswordError(true);
      return;
    }

    if (username === "admin01" && password === "userAdminEditor$45") {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));

      auth.login();
      navigate("/"); // Redirect to '/' after successful login
    } else {
      setUsernameError(true);
      setPasswordError(true);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form
        className="bg-white p-6 rounded-lg shadow-md w-80"
        onSubmit={handleSubmit}
      >
        <input
          className={`border ${
            usernameError ? "border-red-500" : "border-gray-200"
          } hover:border-blue-200 focus:border-blue-600 focus:outline-none rounded-lg w-full p-2 mb-4`}
          value={username}
          onChange={handleUsernameChange}
          type="text"
          placeholder="Username"
        />
        <input
          className={`border ${
            passwordError ? "border-red-500" : "border-gray-200"
          } hover:border-blue-200 focus:border-blue-600 focus:outline-none rounded-lg w-full p-2 mb-4`}
          value={password}
          onChange={handlePasswordChange}
          type="password"
          placeholder="Password"
        />
        <button
          className="bg-blue-600 hover:bg-blue-500 flex justify-center text-gray-50 font-semibold rounded-lg w-full p-2 cursor-pointer"
          type="submit"
        >
          {isLoading ? <div className="spinner-btn"></div> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
