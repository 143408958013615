import React from 'react';
import { imageFilter } from '../filters/imageFilter';

const PostCard = ({ title, img, handleMethod, index, identity, isActive }) => {
  const truncatedTitle = title.length > 65 ? title.substr(0, 65) + '...' : title;

  return (
    <div 
      key={index} 
      onClick={() => handleMethod(identity)} 
      className={`h-64 w-56 p-4 shadow-lg rounded-lg cursor-pointer flex flex-col items-center justify-between ${!isActive ? 'bg-red-200' : 'bg-gray-50' }`}
    >
      <div className='w-44 h-44 bg-gray-100 overflow-hidden rounded-md mb-4'>
        <img 
          src={imageFilter(img)} 
          alt="Banner not available." 
          className='w-full h-full object-cover object-center'
        />
      </div>
      <p className='font-semibold text-sm text-center'>{truncatedTitle}</p>
    </div>
  )
}

export default PostCard;
