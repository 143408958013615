import React, { useState, useEffect } from "react";
import { updatePost } from "../api/posts";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditModal = ({ post, setPosts, onClose }) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTitle(post.title);
    setBody(post.body);
    setIsActive(post.isActive);

    // Disable page scroll when modal is open
    document.body.style.overflow = "hidden";

    // Re-enable page scroll when modal is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [post]);

  const validateFields = () => {
    let isValid = true;
    if (!title.trim()) {
      setTitleError(true);
      isValid = false;
    }

    if (!body.trim()) {
      setBodyError(true);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateFields()) {
      setIsLoading(false);
      return;
    }

    const sanitizeBody = (html) => {
      return html
        .replace(/<p>(\s|&nbsp;|<br>)*<\/p>/g, "")
        .replace(/<p>\s*<\/p>/g, "")
        .replace(/(<br\s*\/?>\s*){2,}/g, "<br>")
        .replace(/(<p><\/p>)+/g, "")
        .replace(/<p><br><\/p>/g, "<br>")
        .replace(/<p>(.*?)<\/p>/g, (match, content) => {
          return content.trim() ? `<p>${content.trim()}</p>` : "";
        });
    };

    const sanitizedBody = sanitizeBody(body);
    const updatedPost = { ...post, title, body: sanitizedBody, isActive };

    try {
      const response = await updatePost(updatedPost);

      if (response.status === 200) {
        setIsLoading(false);
        setPosts((prevPosts) =>
          prevPosts.map((p) => (p.identity === post.identity ? updatedPost : p))
        );
        if (onClose) onClose();
      } else {
        console.error(
          "Failed to update the post. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error updating the post:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    if (e.target.value.trim()) setTitleError(false);
  };

  const handleIsActive = (e) => {
    setIsActive(e.target.checked);
  };

  const handleBodyChange = (value) => {
    setBody(value);
    if (value.trim()) setBodyError(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 animate-fade-in"
        onClick={onClose}
      ></div>

      <div className="relative bg-gray-100 p-8 w-1/2 rounded-lg shadow-lg overflow-hidden">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-xl font-bold cursor-pointer"
        >
          &times;
        </button>

        <form onSubmit={handleSubmit}>
          <div className="flex justify-between">
            <label className="block mb-4">
              <input
                className="border border-gray-200 hover:border-blue-200 focus:border-blue-600 focus:outline-none rounded-lg mr-2"
                checked={isActive}
                onChange={handleIsActive}
                type="checkbox"
              />
              Active
            </label>
            <div className="flex items-center gap-2 pb-10">
              <a
                className="text-blue-500 hover:underline hover:cursor-pointer"
                href={post.url}
                target="_blank"
                rel="noreferrer"
              >
                View Article
              </a>
              <div className="cursor-pointer hover:bg-gray-200 rounded-lg p-1" onClick={() => navigator.clipboard.writeText(post.url)}>
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 14H19C20.1046 14 21 13.1046 21 12V5C21 3.89543 20.1046 3 19 3H12C10.8954 3 10 3.89543 10 5V6.5M5 10H12C13.1046 10 14 10.8954 14 12V19C14 20.1046 13.1046 21 12 21H5C3.89543 21 3 20.1046 3 19V12C3 10.8954 3.89543 10 5 10Z"
                    stroke="#000000"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <input
            className={`border ${
              titleError ? "border-red-500" : "border-gray-200"
            } hover:border-blue-200 focus:border-blue-600 focus:outline-none rounded-lg w-full p-2`}
            value={title}
            onChange={handleTitleChange}
            type="text"
          />
          {/* <div
            className={`text-red-500 transition-opacity duration-300 pb-1 ${
              titleError ? "ease-in-out opacity-100" : "opacity-0"
            }`}
          >
            Field cannot be empty
          </div> */}
          <ReactQuill
            className={`h-60 pb-10 ${
              bodyError ? "border border-red-500" : "border border-gray-200"
            } focus:-blue-600 focus:outline-none rounded-lg w-full`}
            value={body}
            onChange={handleBodyChange}
          />
          {/* <div
            className={`text-red-500 transition-opacity duration-300 pb-1 ${
              bodyError ? "ease-in-out opacity-100" : "opacity-0"
            }`}
          >
            Field cannot be empty
          </div> */}

          <button
            className="bg-blue-600 mt-4 hover:bg-blue-500 flex justify-center text-gray-50 font-semibold rounded-lg w-full p-2 cursor-pointer"
            type="submit"
          >
            {isLoading ? <div className="spinner-btn"></div> : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditModal;
