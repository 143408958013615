import React from 'react'

const CategorySelect = () => {
  return (
    <div>
      <button></button>
    </div>
  )
}

export default CategorySelect