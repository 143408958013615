import React, { useEffect, useState } from "react";
import { getPostsByCategory, getPostsByTitle } from "../api/posts";
import EditModal from "./EditModal";
import PostCard from "./PostCard";

const Posts = ({ category }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [keywordSearch, setKeywordSearch] = useState(false);
  const [categoryValue, setCategoryValue] = useState("Technology");

  const searchInputHandler = (e) => {
    setSearchPhrase(e.target.value);
  };
  const handleSelectChange = (event) => {
    setCategoryValue(event.target.value);
    fetchPosts(1, categoryValue)
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setKeywordSearch(true);
    fetchPosts(1, category || "Technology", searchPhrase);
  };

  const goBack = () => {
    fetchPosts(1, categoryValue)
    setSearchPhrase('')
  }

  const onClose = () => setEditModal(false);

  const fetchPosts = (page, category, keyword) => {
    setLoading(true);
    if (!keyword || keyword === "") {
      setKeywordSearch(false);
      getPostsByCategory(category || "Technology", page)
        .then((res) => {
          setPosts(res);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    } else if (keyword) {
      getPostsByTitle(category, searchPhrase)
        .then((res) => {
          setPosts(res);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
  };

  const selectPost = (identity) => {
    setSelectedPost(posts.find((post) => post.identity === identity));
    setEditModal(true);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  const totalPages = 100;

  const getPaginationRange = () => {
    let start = Math.max(currentPage - 2, 1);
    let end = Math.min(start + 4, totalPages);

    if (currentPage < 3) {
      end = Math.min(5, totalPages);
      start = 1;
    } else if (currentPage > totalPages - 2) {
      start = Math.max(totalPages - 4, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, idx) => start + idx);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-start">
      <form className="flex flex-col pt-8 md:flex-row" onSubmit={handleSearch}>
        <select className="border border-gray-200 hover:border-blue-200 focus:border-blue-600  focus:outline-none rounded-lg mb-4 p-2 mr-2" value={categoryValue} onChange={handleSelectChange}>
          <option value="Technology">Technology</option>
        </select>
        <input
          placeholder="Search by Title"
          className="border w-72 lg:w-search border-gray-200 hover:border-blue-200 focus:border-blue-600 focus:outline-none rounded-lg mb-4 p-2 mr-2"
          onChange={searchInputHandler}
          value={searchPhrase}
        />
        <button
          className="bg-blue-600 hover:bg-blue-500 text-gray-50 font-semibold rounded-lg h-10 w-72 md:w-max px-0 md:px-2 cursor-pointer"
          type="submit"
        >
          🔍
        </button>
      </form>
      <div className="flex flex-row">
        <div className="flex justify-center items-center mr-2">
          <div className="w-5 h-5 bg-gray-50 border border-black rounded mr-1 shadow-lg"></div>
          <div>Enabled</div>
        </div>
        <div className="flex justify-center items-center">
          <div className="w-5 h-5 bg-red-200 rounded mr-1 shadow-lg"></div>
          <div>Disabled</div>
        </div>
        <div></div>
      </div>
      {loading ? (
        <div className="h-screen">
          <div className="spinner bottom-1/2 absolute"></div>
        </div>
      ) : (
        <div className="flex flex-wrap justify-center items-center space-x-4 space-y-4">
          {posts.map((post, index) => (
            <PostCard
              key={post.identity}
              index={index}
              handleMethod={selectPost}
              img={post.image}
              title={post.title}
              identity={post.identity}
              isActive={post.isActive}
            />
          ))}
          {editModal && (
            <EditModal
              onClose={onClose}
              post={selectedPost}
              setPosts={setPosts}
            />
          )}
        </div>
      )}
      {!keywordSearch ? (
        <div className="text-xl flex justify-center space-x-4 py-8">
          {getPaginationRange().map((number) => (
            <button
              className={`px-4 py-2 ${
                number === currentPage
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              key={number}
              onClick={() => paginate(number)}
            >
              {number}
            </button>
          ))}
        </div>
      ) : (
        <div className="text-xl flex justify-center space-x-4 py-8">
          <button onClick={goBack}>Back</button>
        </div>
      )}
    </div>
  );
};

export default Posts;
