import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from "./app/views/Home";
import Navbar from "./app/components/Navbar";
import LoginPage from "./app/views/Login";
import { AuthProvider, useAuth } from './app/context/AuthContext';

function PrivateRoute({ element, ...rest }) {
  const auth = useAuth();

  return auth.isAuthenticated ? element : <Navigate to="/login" />;
}

function App() {
  return (
    <Router className="App">
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<PrivateRoute element={<Home />} />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
