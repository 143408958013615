import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <div className='bg-blue-600 p-6 font-semibold text-xl text-gray-50'>
      <Link to='/'>📰 Content Admin Panel</Link>
    </div>
  )
}

export default Navbar